<template>
  <!-- Order-section -->
  <section class="order-home-section">
    <div class="container">
      <div class="order-flex">
        <div class="order-photo">
          <div class="order-overlay"></div>
          <img src="/assets/images/order-img.png" class="img-fluid" alt="#">
        </div>
        <div class="order-details">
          <h3>التوفيق للزواج</h3>
          <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z" fill="#008275"/>
          </svg>
          <p>مرحبًا بك -ابنتنا الفاضلة- في الموقع، ونسأل الله -تبارك وتعالى- لنا ولك التوفيق والسداد، ونحب أن نؤكد أن الزواج هو الأصل، والفتاة ما ينبغي أن تتأخَّر إذا طرق بابها صاحب الدّين، نسأل الله أن يُقدّر لك الخير ثم يُرضيك به.والدراسة الجامعية يمكن للإنسان أن يستأنفها ولو في عمرٍ متأخر، الدراسة الجامعية لا تعرف حدود الأعمار، ولذلك نقترح تجميد الدراسة الجامعية والدخول في مشروع الزواج، ثم بعد ذلك تستطيعين استئناف دراستك، ويمكن أن تضعي هذا الشرط على زوجك، حتى تتهيأ ظروف إكمال الدراسة، ونسأل الله أن يُعينك على الخير.</p>
          <a href="#!">اطلب الأن</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MarriageSec",
}
</script>
