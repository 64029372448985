<template>

  <PageHeadComponent :page-title="pageTitle"/>

  <section class="mt-4  mb-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div v-if="loading" class="text-center">
                <img :src="'/assets/loading.gif'" alt="loader" width="30">
              </div>
              <div class="table-responsive" v-if="!loading">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <td>#</td>
                    <td>اسم</td>
                    <td>تاريخ الإضافة</td>
                    <td></td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="fav in favorites" :key="fav.id">
                    <td>1</td>
                    <td>{{ fav.user.fullname }}
                      <br>
                      <small>
                        39 سنة
                        -
                        مطلق
                        -
                        يقيم في
                      </small>
                    </td>
                    <td>{{ fav.created_at }}</td>
                    <td>
                      <button class="btn btn-sm" @click="confirmDelete(fav.id)">
                        <i class="fa fa-trash text-danger"></i>
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>


<script>

import ApiRequest from "@/helpers/apiRequest";
import PageHeadComponent from "@/components/PageHeadComponent.vue";
import Swal from 'sweetalert2';


export default {
  name: "FavoritePage",
  components: {
    PageHeadComponent
  },
  data() {
    return {
      pageTitle: "المفضلة",
      favorites: [],
      loading: false
    };
  },
  mounted() {
    this.fetchFavs();
  },
  methods: {
    fetchFavs() {
      this.loading = true;
      ApiRequest.get('/benaa/favorites').then(response => {
        this.favorites = response.data.data;
        this.loading = false;
      }).catch((error) => {
        console.log(error)
      })
    },
    confirmDelete(favId) {
      Swal.fire({
        title: 'هل أنت متأكد؟',
        text: "سيتم حذف هذا العنصر نهائيًا!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'نعم, احذفه!',
        cancelButtonText: 'إلغاء'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteFav(favId);
        }
      });
    },
    deleteFav(favId) {
      this.loading = true;
      ApiRequest.del(`/benaa/favorites/${favId}`)
          .then(() => {
            this.fetchFavs();
            Swal.fire(
                'تم الحذف!',
                'تم حذف العنصر بنجاح.',
                'success'
            );
          })
          .catch(() => {
            this.loading = false;
            Swal.fire(
                'خطأ!',
                'حدث خطأ أثناء محاولة حذف العنصر.',
                'error'
            );
          });
    }
  }
}
</script>