<template>

  <PageHeadComponent :page-title="pageTitle"/>

  <section class="search-marriage-section">
    <div class="container">
      <div class="sec-title wow fadeInUp" data-wow-delay="0.2s">
        <h3>فلتر البحث</h3>
        <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z"
              fill="#1E354C"/>
        </svg>
      </div>
      <div class="search-marriage-blog">
        <h3>شروط الرجل</h3>
        <div class="range-slider-flex">
          <div class="range-content">
            <span class="text-range">العمر</span>
            <div id="ageSlider" class="age-slider"></div>
          </div>
          <div class="range-content">
            <span class="text-range">الوزن</span>
            <div class="sliders"></div>
          </div>
          <div class="range-content">
            <span class="text-range">الطول</span>
            <div class="sliderss"></div>
          </div>
        </div>
        <div class="select-flex">
          <div class="search-select-blog">
            <span>مكان الإقامة</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>مكان الإقامة</option>
                  <option value="1">مكان الإقامة 1</option>
                  <option value="2">مكان الإقامة 2</option>
                  <option value="3">مكان الإقامة 3</option>
                  <option value="4">مكان الإقامة 4</option>
                  <option value="5">مكان الإقامة 5</option>
                  <option value="6">مكان الإقامة 6</option>
                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الجنسية</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>الجنسية</option>
                  <option value="1">الجنسية</option>
                  <option value="2">الجنسية</option>
                  <option value="3">الجنسية</option>
                  <option value="4">الجنسية</option>
                  <option value="5">الجنسية</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الأصل</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>الأصل</option>
                  <option value="1">الأصل</option>
                  <option value="2">الأصل</option>
                  <option value="3">الأصل</option>
                  <option value="4">الأصل</option>
                  <option value="5">الأصل</option>
                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>القبيلة</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>القبيلة</option>
                  <option value="1">القبيلة</option>
                  <option value="2">القبيلة</option>
                  <option value="3">القبيلة</option>
                  <option value="4">القبيلة</option>
                  <option value="5">القبيلة</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>التعليم</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>التعليم</option>
                  <option value="1">التعليم</option>
                  <option value="2">التعليم</option>
                  <option value="3">التعليم</option>
                  <option value="4">التعليم</option>
                  <option value="5">التعليم</option>
                  <option value="6">التعليم</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>طبيعة العمل</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>طبيعة العمل</option>
                  <option value="1">طبيعة العمل</option>
                  <option value="2">طبيعة العمل</option>
                  <option value="3">طبيعة العمل</option>
                  <option value="4">طبيعة العمل</option>
                  <option value="5">طبيعة العمل</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الحالة الاجتماعية</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>الحالة الاجتماعية</option>
                  <option value="1">الحالة الاجتماعية</option>
                  <option value="2">الحالة الاجتماعية</option>
                  <option value="3">الحالة الاجتماعية</option>
                  <option value="4">الحالة الاجتماعية</option>
                  <option value="5">الحالة الاجتماعية</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الحالة المادية</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>الحالة المادية</option>
                  <option value="1">الحالة المادية</option>
                  <option value="2">الحالة المادية</option>
                  <option value="3">الحالة المادية</option>
                  <option value="4">الحالة المادية</option>
                  <option value="5">الحالة المادية</option>
                  <option value="6">الحالة المادية</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الحالة الصحية</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>الحالة الصحية</option>
                  <option value="1">الحالة الصحية</option>
                  <option value="2">الحالة الصحية</option>
                  <option value="3">الحالة الصحية</option>
                  <option value="4">الحالة الصحية</option>
                  <option value="5">الحالة الصحية</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>مستوى التدين</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>مستوى التدين</option>
                  <option value="1">مستوى التدين</option>
                  <option value="2">مستوى التدين</option>
                  <option value="3">مستوى التدين</option>
                  <option value="4">مستوى التدين</option>
                  <option value="5">مستوى التدين</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الجمال</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>الجمال</option>
                  <option value="1">الجمال</option>
                  <option value="2">الجمال</option>
                  <option value="3">الجمال</option>
                  <option value="4">الجمال</option>
                  <option value="5">الجمال</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>لون البشرة</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>لون البشرة</option>
                  <option value="1">لون البشرة</option>
                  <option value="2">لون البشرة</option>
                  <option value="3">لون البشرة</option>
                  <option value="4">لون البشرة</option>

                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="search-text-blog">
          <span>اكتب بإختصار مواصفات الزوجة التي تريد</span>
          <div class="form-group cont required">
            <textarea></textarea>
          </div>
        </div>
        <div class="search-send">
          <button type="button" class="btn">بحث</button>
        </div>
      </div>
      <div class="sec-title wow fadeInUp" data-wow-delay="0.2s">
        <h3>نتائج البحث</h3>
        <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z"
              fill="#1E354C"/>
        </svg>
      </div>
      <div class="search-result-blogs">
        <div class="search-result-flex">

          <div class="search-result-blog" v-for="(value, key) in matches" :key="key">
            <a :href="'/matches/' +  value.username">
              <h3>{{value.name}}</h3>
              <div class="result-title">
                <span>{{ value.age }}</span>
                <span> سنه </span>
                <span> - </span>
                <span> {{value.nationality}} </span>
              </div>
              <div class="result-details">
                <div class="resu-age">
                  <i class="uil uil-location-point"></i>
                  {{value.nationality}}
                </div>
                <div class="resu-age">
                  <i class="uil uil-users-alt"></i>
                  {{value.marital_status}}
                </div>
                <div class="resu-age">
                  <i class="uil uil-chat-bubble-user"></i>
                  {{value.job}}
                </div>
                <div class="resu-age">
                  <i class="uil uil-comment-dots"></i>التفاصيل
                </div>
              </div>
            </a>
          </div>

        </div>
      </div>
    </div>
  </section>


</template>


<script>
import PageHeadComponent from "@/components/PageHeadComponent.vue";
import ApiRequest from "@/helpers/apiRequest";

export default {
  name: "MatchesIndex",
  components: {
    PageHeadComponent
  },
  data() {
    return {
      pageTitle: "الزواج",
      matches: []
    };
  },
  mounted() {
    this.fetchMatches();
  },
  methods: {
    fetchMatches() {
      ApiRequest.get('/benaa/get-matches').then(response => {
        this.matches = response.data.data;
      }).catch((error) => {
        console.log(error)
      })
    },
  }
}
</script>

