<template>
  <!-- Consulting Section -->
  <section class="consulting-section">
    <img src="/assets/images/pat-cons.png" class="about-curve">
    <div class="container">
      <div class="sec-title wow fadeInUp" data-wow-delay="0.2s">
        <h3>الاستشارات</h3>
        <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z" fill="#1E354C"/>
        </svg>
      </div>
      <div class="about-collapse">
        <div class="faq-div wow fadeInUp" data-wow-delay="0.2s">
          <h4 class="faq-title fixall">
            <p class="faq-text">
              هل أكمل دراستي أم أتزوج وأغادر البلد؟
            </p>
            <i class="uil uil-plus"></i>
          </h4>
          <div class="faq-body">
            <div class="faq-flex">
              <div class="faq-flex-photo">
                <img src="/assets/images/ring.png" class="img-fluid" alt="#">
              </div>
              <div class="faq-flex-title">

                <p>
                  مرحبًا بك -ابنتنا الفاضلة- في الموقع، ونسأل الله -تبارك وتعالى- لنا ولك التوفيق والسداد، ونحب أن نؤكد أن الزواج هو الأصل، والفتاة ما ينبغي أن تتأخَّر إذا طرق بابها صاحب الدّين، نسأل الله أن يُقدّر لك الخير ثم يُرضيك به.والدراسة الجامعية يمكن للإنسان أن يستأنفها ولو في عمرٍ متأخر، الدراسة الجامعية لا تعرف حدود الأعمار، ولذلك نقترح تجميد الدراسة الجامعية والدخول في مشروع الزواج، ثم بعد ذلك تستطيعين استئناف دراستك، ويمكن أن تضعي هذا الشرط على زوجك، حتى تتهيأ ظروف إكمال الدراسة، ونسأل الله أن يُعينك على الخير.
                  <a href="#!" class="link-more">... المزيد</a>
                </p>
              </div>
            </div>

          </div>
        </div>
        <div class="faq-div wow fadeInUp" data-wow-delay="0.4s">
          <h4 class="faq-title fixall">
            <p class="faq-text">
              أعاني من فتور الهمة والإحباط...ساعدوني لتجاوز ذلك!
            </p>
            <i class="uil uil-plus"></i>
          </h4>
          <div class="faq-body">
            <div class="faq-flex">
              <div class="faq-flex-photo">
                <img src="/assets/images/course1.jpg" class="img-fluid" alt="#">
              </div>
              <div class="faq-flex-title">

                <p>
                  مرحبًا بك -ابنتنا الفاضلة- في الموقع، ونسأل الله -تبارك وتعالى- لنا ولك التوفيق والسداد، ونحب أن نؤكد أن الزواج هو الأصل، والفتاة ما ينبغي أن تتأخَّر إذا طرق بابها صاحب الدّين، نسأل الله أن يُقدّر لك الخير ثم يُرضيك به.والدراسة الجامعية يمكن للإنسان أن يستأنفها ولو في عمرٍ متأخر، الدراسة الجامعية لا تعرف حدود الأعمار، ولذلك نقترح تجميد الدراسة الجامعية والدخول في مشروع الزواج، ثم بعد ذلك تستطيعين استئناف دراستك، ويمكن أن تضعي هذا الشرط على زوجك، حتى تتهيأ ظروف إكمال الدراسة، ونسأل الله أن يُعينك على الخير.
                  <a href="#!" class="link-more">... المزيد</a>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="view-event">
        <a href="#!" class="view-one">طلب استشارة</a>
        <a href="#!" class="view-two">عرض الجميع</a>
      </div>

    </div>
  </section>

</template>


<script>
export default {
  name: "ConsultingSec",
}
</script>
