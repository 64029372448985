import {createRouter, createWebHistory} from "vue-router";
import IndexPage from "@/pages/Index/IndexPage.vue";
import LoginPage from "@/pages/Auth/LoginPage.vue";
import RegisterPage from "@/pages/Auth/RegisterPage.vue";
import AboutPage from "@/pages/AboutPage.vue";
import ConsultationsPage from "@/pages/ConsultationsPage.vue";
import MatchesIndex from "@/pages/Matches/MatchesIndex";
import MatchesShow from "@/pages/Matches/MatchesShow";
import ContactPage from "@/pages/ContactPage.vue";
import CoursesIndex from "@/pages/Courses/CoursesIndex.vue";
import AccountPage from "@/pages/AccountPage";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import FavoritePage from "@/pages/FavoritePage";
import SpecificationPage from "@/pages/SpecificationPage";

import NProgress from 'nprogress';


export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "index",
            component: IndexPage,
        },
        {
            path: "/login",
            name: "login",
            component: LoginPage,
        },
        {
            path: "/register",
            name: "Register",
            component: RegisterPage,
        },
        {
            path: "/about",
            name: "about",
            component: AboutPage,
        },
        {
            path: "/contact",
            name: "contact",
            component: ContactPage,
        },
        {
            path: "/consultations",
            name: "consultations",
            component: ConsultationsPage,
        },
        {
            path: "/matches",
            name: "matches",
            component: MatchesIndex,
            meta: {
                requiresAuth: true
            },
        },
        {
            path: "/matches/:username",
            name: "matches-show",
            component: MatchesShow,
            meta: {
                requiresAuth: true
            },
            props: true
        },
        {
            path: "/courses",
            name: "courses",
            component: CoursesIndex,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: "/account",
            name: "account",
            component: AccountPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/profile",
            name: "profile",
            component: ProfilePage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/favorites",
            name: "favorites",
            component: FavoritePage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/specifications",
            name: "specifications",
            component: SpecificationPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/:catchAll(.*)",
            name: "not-found",
            component: NotFoundPage,
        }
    ],
});

router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('token');
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!token) {
            next('/login');
        } else {
            next();
        }
    } else {
        if (token && to.name === 'login') {
            next({name: 'account'});
        } else {
            next();
        }
    }
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    NProgress.done();
});