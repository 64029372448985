<template>
  <div v-if="alerts.length > 0" class="messages">
    <div v-for="(message, index) in alerts" :key="index" class="alert alert-primary">
      {{ message }}
    </div>

  </div>
</template>

<script>
export default {
  name: "AlertsComponent",
  props: {
    alerts: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.messages {
  margin-top: 20px;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
}
</style>
