<template>
  <section class="news-home-section">
    <div class="container">
      <div class="sec-title news wow fadeInUp" data-wow-delay="0.2s">
        <h3>الأخبار</h3>
        <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z"
              fill="#1E354C"/>
        </svg>
      </div>
      <div class="blogSlider owl-carousel">
        <div class="item wow zoomIn">
          <div class="blog">
            <div class="img-here">
              <a href="#!" class="blog-img fixall">
                <img src="/assets/images/blog1.jpg" class="img-fluid">
              </a>
              <div class="date">
                <span class="day">25</span>
                <span class="month">يناير</span>
              </div>
            </div>
            <div class="text-here">
              <a href="#!" class="blog-name fixall">
                اسم أو عنوان الخبر المراد كتابته
              </a>
              <p class="blog-text fixall">
                هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض
                النوادر أو الكلمات العشوائية إلى النص
              </p>
            </div>
          </div>
        </div>
        <div class="item wow zoomIn" data-wow-delay="0.2s">
          <div class="blog">
            <div class="img-here">
              <a href="#!" class="blog-img fixall">
                <img src="/assets/images/blog2.jpg" class="img-fluid">
              </a>
              <div class="date">
                <span class="day">26</span>
                <span class="month">يناير</span>
              </div>
            </div>
            <div class="text-here">
              <a href="#!" class="blog-name fixall">
                اسم أو عنوان الخبر المراد كتابته
              </a>
              <p class="blog-text fixall">
                هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض
                النوادر أو الكلمات العشوائية إلى النص
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cont wow fadeInUp">
        <a href="#!" class="fixall">
          المزيد
          <i class="fas fa-arrow-left"></i>
        </a>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "NewsSec",
}
</script>
