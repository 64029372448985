<template>

  <PageHeadComponent :page-title="pageTitle"/>

  <section class="account-section">
    <div class="container">
      <div class="account-flex">
        <ul class="list-unstyled">
          <li>
            <a :href="'/profile'">
              <i class="uil-edit-alt"></i>
              <span>تحرير معلوماتى</span>
            </a>
          </li>

          <li>
            <a :href="'/favorites'">
              <i class="uil-heart-alt"></i>
              <span>المفضلة</span>
            </a>
          </li>

          <li>
            <a :href="'/specifications'">
              <i class="uil-package"></i>
              <span>مواصفات الشريك</span>
            </a>
          </li>

          <li>
            <a href="#!">
              <i class="uil-book-open"></i>
              <span>دوراتى</span>
            </a>
          </li>
          <li>
            <a href="#!">
              <i class="uil-comment-alt-question"></i>
              <span>استشاراتى</span>
            </a>
          </li>
          <li>
            <a href="#!">
              <i class="uil-newspaper"></i>
              <span>القائمة البريدية</span>
            </a>
          </li>

        </ul>
      </div>
    </div>
  </section>
</template>

<script>

import PageHeadComponent from "@/components/PageHeadComponent.vue";

export default {
  name: "AccountPage",
  components: {
    PageHeadComponent
  },
  data() {
    return {
      pageTitle: "الملف الشخصى",
    };
  },
}
</script>