<template>

  <div class="menu-overlay"></div>
  <!-- Start preloader -->
  <div class="pre-loader">
    <div class="content"><img src="/assets/images/logo.png" alt="logo"/>
      <div class="progress-bar"><span class="bar"><span class="progress"></span></span></div>
    </div>
  </div>
  <!-- Start Header -->
  <header>
    <img src="/assets/images/curve1.png" alt="" class="head-curve">

    <div class="container">
      <div class="header">
        <div class="logo-cont">

          <a href="/" class="logo">
            <img src="/assets/images/logo.png" alt="" class="img-fluid">
          </a>
        </div>

        <nav>
          <i class="uil uil-multiply close-menu"></i>
          <ul class="mo-navbar fixall list-unstyled">

            <router-link class="active" to="/">الرئيسية</router-link>

            <li>
              <router-link to="about">من نحن</router-link>
            </li>

            <li>
              <router-link to="courses">الدورات</router-link>
            </li>
            <li>
              <router-link to="consultations">الإستشارات و الأسئلة</router-link>
            </li>
            <li>
              <router-link to="matches">الزواج</router-link>
            </li>
            <li>
              <router-link to="contact">اتصل بنا</router-link>
            </li>
          </ul>
        </nav>

        <div class="header-btns">
          <span class="menu-btn uil uil-bars"></span>
          <div class="user-list">
            <button class="user-btn fixall">
              <i class="uil uil-user"></i>
            </button>
            <div class="user-dropdown">
              <router-link class="fixall drop-link" v-if="!isLoggedIn" to="/login">تسجيل دخول</router-link>
              <router-link class="fixall drop-link" v-if="!isLoggedIn" to="/register">تسجيل جديد</router-link>


              <router-link class="page-scroll ml-4" v-if="isLoggedIn && user" to="/account">{{
                  user.fullname
                }}
              </router-link>
              <a href="javascript:void(0)" class="theme-btn" v-if="isLoggedIn" @click="logout">{{
                  $t('head.logout')
                }}</a>
            </div>
          </div>
          <a v-if="!isRTL" href="#" @click="setLocale('ar')" class="contact-btn fixall">
            <i class="uil uil-globe"></i>
          </a>

          <a v-if="isRTL" href="#" @click="setLocale('en')" class="contact-btn fixall">
            <i class="uil uil-globe"></i>
          </a>

        </div>
      </div>
    </div>
  </header>

</template>

<script>

import {mapState} from "vuex";

export default {
  name: "HeaderComponent",

  computed: {
    ...mapState(['isLoggedIn', 'user']),
    isRTL() {
      return this.$i18n.locale === "ar";
    },
  },

  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      document.body.setAttribute("dir", this.$i18n.locale === "ar" ? "rtl" : "ltr");
      localStorage.setItem("locale", locale);
      location.reload();
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.$store.commit('setIsLoggedIn', false);
      this.$router.push('/login');
    },
  },
}

</script>

