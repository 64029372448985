<template>

  <!-- Start Tabs -->
  <section class="tabs-sec" id="course">
    <div class="container">
      <div class="sec-title wow fadeInUp" data-wow-delay="0.2s">
        <h3>الدورات التي نقدمها</h3>
        <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z" fill="#1E354C"/>
        </svg>
      </div>

      <ul class="tab-ul fixall list-unstyled wow fadeInUp" data-wow-delay="0.2s">
        <li class="tab-li">
          <a tabindex="" class="tab-a fixall active" data-filter="all">
            الجميع
          </a>
        </li>
        <li class="tab-li">
          <a tabindex="" class="tab-a fixall" data-filter="medicine">
            الطب
          </a>
        </li>
        <li class="tab-li">
          <a tabindex="" class="tab-a fixall" data-filter="eng">
            الهندسة
          </a>
        </li>
        <li class="tab-li">
          <a tabindex="" class="tab-a fixall" data-filter="sceince">
            العلوم
          </a>
        </li>
        <li class="tab-li">
          <a tabindex="" class="tab-a fixall" data-filter="business">
            بيــزنس
          </a>
        </li>
        <li class="tab-li">
          <a tabindex="" class="tab-a fixall" data-filter="programing">
            برمجــة
          </a>
        </li>
        <li class="tab-li">
          <a tabindex="" class="tab-a fixall" data-filter="art">
            فنــون
          </a>
        </li>
        <li class="tab-li">
          <a tabindex="" class="tab-a fixall" data-filter="marketing">
            تسويق
          </a>
        </li>
      </ul>
      <div class="courses-grid">
        <div class="course medicine wow zoomIn">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course1.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  الطب
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course eng wow zoomIn" data-wow-delay="0.1s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course3.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  الهندسة
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course sceince wow zoomIn" data-wow-delay="0.2s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course5.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  العلوم
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course business wow zoomIn" data-wow-delay="0.3s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course2.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  بيــزنس
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course programing wow zoomIn" data-wow-delay="0.2s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course1.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  برمجــة
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course art wow zoomIn" data-wow-delay="0.3s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course4.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  فنــون
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course marketing wow zoomIn" data-wow-delay="0.4s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course1.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  تسويق
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course medicine wow zoomIn" data-wow-delay="0.3s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course2.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  الطب
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course eng wow zoomIn" data-wow-delay="0.4s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course4.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  الهندسة
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course sceince wow zoomIn" data-wow-delay="0.5s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course6.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  العلوم
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course business wow zoomIn" data-wow-delay="0.4s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course4.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  بيــزنس
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course programing wow zoomIn" data-wow-delay="0.5s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course3.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  برمجــة
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course art wow zoomIn" data-wow-delay="0.6s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course4.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  فنــون
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course marketing wow zoomIn" data-wow-delay="0.5s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course6.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  تسويق
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="course eng wow zoomIn" data-wow-delay="0.6s">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course5.jpg" class="img-fluid">
            </a>
            <div class="mo-rating">
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star active"></i>
              <i class="fas fa-star"></i>
            </div>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                  أحمد صلاح زردق
                </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  الهندسة
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                8
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="more-tabs fadeInUp">
        <a href="#!" class="all-link fixall">
          عرض الجميع
        </a>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "TabsSec",
}
</script>
