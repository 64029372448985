import {createI18n} from 'vue-i18n'
import en from "./lang/en.json"
import ar from "./lang/ar.json"

const messages = {
    en,
    ar
}

const locale = localStorage.getItem("locale") || "en";

const i18n = createI18n({
    locale,
    messages
})

export default i18n