<template>
  <section class="about-head-section">
    <div class="container">
      <div class="text-title">
        <h3>{{ pageTitle }}</h3>
        <ul class="breadcrumb">
          <li>
            <a href="/"> الرئيسية</a>
          </li>

          <li>
            <a href="#"> {{ pageTitle }} </a>
          </li>
        </ul>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "PageHeadComponent",
  props: {
    pageTitle: {
      type: String,
      required: true
    }
  }
};
</script>
