<template>

  <PageHeadComponent :page-title="pageTitle"/>

  <section class="search-marriage-section">
    <div class="container">
      <div class="sec-title wow fadeInUp" data-wow-delay="0.2s">
        <h3>المواصفات</h3>
        <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z"
              fill="#1E354C"/>
        </svg>
      </div>

      <div v-if="loading" class="text-center mt-4 mb-4 loader">
        <img :src="'/assets/loading.gif'" alt="loader" width="30">
      </div>

      <div v-if="!loading" class="search-marriage-blog">

        <div class="select-flex">
          <div class="search-select-blog">
            <span>مكان الإقامة</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>مكان الإقامة</option>
                  <option value="1">مكان الإقامة 1</option>
                  <option value="2">مكان الإقامة 2</option>
                  <option value="3">مكان الإقامة 3</option>
                  <option value="4">مكان الإقامة 4</option>
                  <option value="5">مكان الإقامة 5</option>
                  <option value="6">مكان الإقامة 6</option>
                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الجنسية</span>
            <div class="form-group te">
              <div class="cont">
                <select v-model="newSpecifications.nationality" class="form-select"
                        aria-label=".form-select-lg example">
                  <option disabled selected>الجنسية</option>
                  <option v-for="(index, nation) in selectableData.nationalities" :key="nation" :value="nation">
                    {{ index }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الأصل</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>الأصل</option>
                  <option value="1">الأصل</option>
                  <option value="2">الأصل</option>
                  <option value="3">الأصل</option>
                  <option value="4">الأصل</option>
                  <option value="5">الأصل</option>
                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>القبيلة</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>القبيلة</option>
                  <option value="1">القبيلة</option>
                  <option value="2">القبيلة</option>
                  <option value="3">القبيلة</option>
                  <option value="4">القبيلة</option>
                  <option value="5">القبيلة</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>التعليم</span>
            <div class="form-group te">
              <div class="cont">
                <select v-model="newSpecifications.education" class="form-select"
                        aria-label=".form-select-lg example">
                  <option disabled selected>التعليم</option>
                  <option v-for="(index, edu) in selectableData.education" :key="edu" :value="edu">
                    {{ index }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>طبيعة العمل</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>طبيعة العمل</option>
                  <option value="1">طبيعة العمل</option>
                  <option value="2">طبيعة العمل</option>
                  <option value="3">طبيعة العمل</option>
                  <option value="4">طبيعة العمل</option>
                  <option value="5">طبيعة العمل</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الحالة الاجتماعية</span>
            <div class="form-group te">
              <div class="cont">
                <select v-model="newSpecifications.marital_status" class="form-select"
                        aria-label=".form-select-lg example">
                  <option disabled selected>الحالة الاجتماعية</option>
                  <option v-for="(index, mat) in selectableData.marital_status" :key="mat" :value="mat">
                    {{ index }}
                  </option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الحالة المادية</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>الحالة المادية</option>
                  <option value="1">الحالة المادية</option>
                  <option value="2">الحالة المادية</option>
                  <option value="3">الحالة المادية</option>
                  <option value="4">الحالة المادية</option>
                  <option value="5">الحالة المادية</option>
                  <option value="6">الحالة المادية</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الحالة الصحية</span>
            <div class="form-group te">
              <div class="cont">
                <select v-model="newSpecifications.health_status" class="form-select"
                        aria-label=".form-select-lg example">
                  <option disabled selected>الحالة الصحية</option>
                  <option v-for="(index, hel) in selectableData.health_status" :key="hel" :value="hel">
                    {{ index }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>مستوى التدين</span>
            <div class="form-group te">
              <div class="cont">
                <select v-model="newSpecifications.religion" class="form-select"
                        aria-label=".form-select-lg example">
                  <option disabled selected>مستوى التدين</option>
                  <option v-for="(index, reli) in selectableData.religion" :key="reli" :value="reli">
                    {{ index }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>الجمال</span>
            <div class="form-group te">
              <div class="cont">
                <select class="form-select" aria-label=".form-select-lg example">
                  <option selected>الجمال</option>
                  <option value="1">الجمال</option>
                  <option value="2">الجمال</option>
                  <option value="3">الجمال</option>
                  <option value="4">الجمال</option>
                  <option value="5">الجمال</option>

                </select>
              </div>
            </div>
          </div>
          <div class="search-select-blog">
            <span>لون البشرة</span>
            <div class="form-group te">
              <div class="cont">
                <select v-model="newSpecifications.skin_color" class="form-select"
                        aria-label=".form-select-lg example">
                  <option disabled selected>لون البشرة</option>
                  <option v-for="(index, col) in selectableData.skin_colors" :key="col" :value="col">
                    {{ index }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="search-text-blog">
          <span>اكتب بإختصار مواصفات الشريك المرغوبة</span>
          <div class="form-group cont required">
            <textarea v-model="newSpecifications.partner_specifications"></textarea>
          </div>
        </div>
        <div class="search-send">
          <button type="button" @click="saveSpecifications"
                  :disabled="isButtonClicked"
                  class="btn">
            حفظ
            <i v-if="isButtonClicked" class="fa fa-spinner fa-spin mx-2"></i>
          </button>
        </div>
        <AlertsComponent :messages="alerts"/>
      </div>

    </div>
  </section>


</template>


<script>

import ApiRequest from "@/helpers/apiRequest";
import PageHeadComponent from "@/components/PageHeadComponent.vue";
import AlertsComponent from "@/components/AlertsComponent.vue";


export default {
  name: "SpecificationPage",
  components: {
    PageHeadComponent,
    AlertsComponent
  },
  data() {
    return {
      pageTitle: "مواصفات الشريك",
      selectableData: [],
      current_specifications: {},
      newSpecifications: {},
      loading: false,
      isButtonClicked: false,
      alerts: []
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.loading = true;
      ApiRequest.get('/benaa/edit-specifications').then(response => {
        this.selectableData = response.data.selectableData;
        this.current_specifications = response.data.current_specifications;
        this.newSpecifications = {...this.current_specifications};
        this.loading = false;
      }).catch((error) => {
        console.log(error)
      })
    },

    saveSpecifications() {
      this.isButtonClicked = true;
      ApiRequest.post('/benaa/store-specifications', this.newSpecifications).then(() => {
        this.alerts = ["تم حفظ المواصفات بنجاح"];
        this.isButtonClicked = false;
        setTimeout(()=>{
          window.location.reload();
        }, 1000)
      }).catch((error) => {
        this.isButtonClicked = false;
        if (error.status_code !== 200) {
          this.errors = [error.response.message]
        }
      })
    }

  }
}
</script>