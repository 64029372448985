<template>

  <PageHeadComponent :page-title="pageTitle"/>

  <section class="contact-page-in">
    <div class="container">
      <div class="row cont-flex">
        <div class="col-md-6">
          <div class="contact-us details">

            <div class="form-group foot-na cont required">
              <input type="text" class="form_input" placeholder="الاسم ">
            </div>
            <div class="form-group foot-na cont">
              <input type="email" class="form_input" placeholder="البريد الإلكتروني">
            </div>
            <div class="form-group foot-na cont required">
              <input type="number" class="form_input" placeholder="التليفون">
            </div>
            <div class="form-group">

              <div class="cont">
                <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                  <option selected>عنوان الموضوع</option>
                  <option value="1">عنوان الموضوع</option>
                  <option value="2">عنوان الموضوع</option>
                  <option value="3">عنوان الموضوع</option>
                  <option value="4">عنوان الموضوع</option>
                  <option value="5">عنوان الموضوع</option>
                  <option value="6">اخرى</option>

                </select>
              </div>
            </div>

            <div class="form-group cont required">
              <textarea placeholder="الموضوع"></textarea>

            </div>
            <div class="ask-send">
              <button type="button" class="btn">إرسال</button>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="contact-us details">
            <h2>منصة بناء الاسرية</h2>

            <ul class="list-unstyled f-ti">
              <li>
                <i class="fas fa-map-marker-alt"></i>
                <span>السعودية - الرياض</span>
              </li>
              <li>
                <a href="tel:006920000000" class="phone-ic">
                  <i class="fas fa-phone"></i>
                  <span> 006920000000 </span>
                </a>
              </li>
              <li>
                <a href="https://wa.me/006920000000">
                  <i class="fab fa-whatsapp"></i>
                  <span>006920000000</span>
                </a>
              </li>
              <li>
                <a href="mailto:info@DS.com">
                  <i class="fas fa-envelope"></i>
                  <span>info@ DS.com</span>
                </a>
              </li>
            </ul>

          </div>
        </div>


      </div>
    </div>
  </section>
</template>

<script>

import PageHeadComponent from "@/components/PageHeadComponent.vue";

export default {
  name: "ContactPage",
  components: {
    PageHeadComponent
  },
  data() {
    return {
      pageTitle: "اتصل بنا",
    };
  },
}
</script>