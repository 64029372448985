import axios from 'axios';
import {API_URL} from './config.js';

const token = localStorage.getItem('token');

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        Authorization: `Bearer ${token}`,
    },
});

const ApiRequest = {
    get(url, params) {
        return axiosInstance.get(url, {params});
    },

    post(url, data) {
        return axiosInstance.post(url, data);
    },

    put(url, data) {
        return axiosInstance.put(url, data);
    },

    del(url) {
        return axiosInstance.delete(url);
    },

};

export default ApiRequest;