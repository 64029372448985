import { createApp } from 'vue'
import App from './App.vue'
import i18n from './helpers/i18n'
import store from "./store";
import {router} from "./router";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(i18n);


app.mount("#app");



