<template>

  <PageHeadComponent :page-title="pageTitle"/>

  <section class="latest-courses">
    <div class="container">
      <div class="sec-title news wow fadeInUp" data-wow-delay="0.2s">
        <h3>أشهر الدورات</h3>
        <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z"
              fill="#1E354C"/>
        </svg>
      </div>


      <carousel :items-to-show="slidesNo" dir="rtl">

        <slide v-for="(course, index) in topCourses" :key="index">
          
          <div class="course wow zoomIn">
            <div class="img-here">
              <a href="#!" class="course-img">
                <img v-if="course.imageLink" :src="course.imageLink" class="img-fluid">
                <img v-else :src="course.image" class="img-fluid">
              </a>
              <div class="price">
                {{ course.cost }} رس
              </div>
            </div>
            <div class="info-here">
              <div class="course-head">
                <a href="#!" class="auther fixall">
                  <img :src="course.teacher.image" class="img-fluid img">
                  <span class="name">
                    {{ course.teacher.fullName }}
                        </span>
                </a>
                <div class="tags">
                  <a href="#!" class="fixall tag type">
                    {{ course.category }}
                  </a>
                  <a href="#!" class="fixall tag level entry">
                    {{ course.level }}
                  </a>
                </div>
              </div>
              <a href="#!" class="course-name fixall">
                {{ course.name }}
              </a>
              <p class="course-desc fixall">
                {{ course.about }}
              </p>
              <div class="course-foot">
                <div class="foot-item">
                  {{ course.stats.lessonsCount }}
                  <i class="uil uil-file"></i>
                  <span class="lnr lnr-file-empty"></span>
                </div>
                <div class="foot-item">
                  {{ course.stats.totalHours }}
                  <i class="uil uil-clock"></i>
                  <span class="lnr lnr-clock"></span>
                </div>
                <div class="foot-item">
                  {{ course.stats.studentsCount }}
                  <i class="uil uil-user"></i>
                </div>
              </div>
            </div>
          </div>

        </slide>

        <template #addons>
        </template>

      </carousel>


      <!-- <div class="latest-cour owl-carousel">
        <div class="course wow zoomIn">
          <div class="img-here">
            <a href="#!" class="course-img">
              <img src="/assets/images/course1.jpg" class="img-fluid">
            </a>
            <div class="price">
              30 رس
            </div>
          </div>
          <div class="info-here">
            <div class="course-head">
              <a href="#!" class="auther fixall">
                <img src="/assets/images/user1.png" class="img-fluid img">
                <span class="name">
                        أحمد صلاح زردق
                      </span>
              </a>
              <div class="tags">
                <a href="#!" class="fixall tag type">
                  الطب
                </a>
                <a href="#!" class="fixall tag level entry">
                  مبتدئ
                </a>
              </div>
            </div>
            <a href="#!" class="course-name fixall">
              مقدمة في الهندسة الميكانيكية.
            </a>
            <p class="course-desc fixall">
              ملخص قصيــر عــــن الـدورة التدريبيــة المقــدمة عن الهندسة الميكانيكية
            </p>
            <div class="course-foot">
              <div class="foot-item">
                120
                <i class="uil uil-file"></i>
                <span class="lnr lnr-file-empty"></span>
              </div>
              <div class="foot-item">
                12hr
                <i class="uil uil-clock"></i>
                <span class="lnr lnr-clock"></span>
              </div>
              <div class="foot-item">
                1221
                <i class="uil uil-user"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </section>
  <!-- Start All-courses -->
  <section class="all-courses-section">
    <div class="container">
      <div class="sec-title wow fadeInUp" data-wow-delay="0.2s">
        <h3>جميع الدورات</h3>
        <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z"
              fill="#1E354C"/>
        </svg>
      </div>
      <div class="cate-search-flex-sm">
        <div class="search-bar">
          <input class="searchinput" id="search" placeholder="عن اي دورة تبحث" type="search" v-model="filter.name">
          <button class="se_on" type="submit" @click="filterByName"><i class="fas fa-search"></i></button>
        </div>
        <div class="filter-bar">
          <span>ترتيب على حسب</span>
          <div class="form-group te">
            <div class="cont">
              <select class="form-select" aria-label=".form-select-lg example" v-model="sortOptionIndex" @change="sort">
                <option v-for="(sortOption, index) in sortArray" :key="index" :value="index">{{ sortOption.placeHolder }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="cate-flex">
        <div class="cate-link">
          <h3>التصنيفات</h3>
          <ul class="tab-all fixall list-unstyled wow fadeInUp" data-wow-delay="0.2s">
            <li class="tab-li">
              <a tabindex="" class="tab-a fixall" :class="isActive(0)" data-filter="all"  @click="filterByCat(0)">
                الجميع
              </a>
            </li>
            <li class="tab-li" v-for="(category, index) in categories" :key="index">
              <a tabindex="" class="tab-a fixall" :class="isActive(category.id)" :data-filter="category.name" @click="filterByCat(category.id)">
                {{ category.name }}
              </a>
            </li>
          </ul>

        </div>
        <div class="cate-courses">
          <div class="cate-search-flex">
            <div class="search-bar">
              <input class="searchinput" id="search" placeholder="عن اي دورة تبحث" type="search" v-model="filter.name">
              <button class="se_on" type="submit" @click="filterByName"><i class="fas fa-search"></i></button>
            </div>
            <div class="filter-bar">
              <span>ترتيب على حسب</span>
              <div class="form-group te">
                <div class="cont">
                  <select class="form-select" aria-label=".form-select-lg example" v-model="sortOptionIndex" @change="sort">
                    <option v-for="(sortOption, index) in sortArray" :key="index" :value="index">{{ sortOption.placeHolder }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="all-courses-grid">
            <div class="course eng wow zoomIn" data-wow-delay="0.6s" v-for="(course, index) in courses" :key="index">
              <div class="img-here">
                <a href="#!" class="course-img">
                  <img v-if="course.imageLink" :src="course.imageLink" class="img-fluid">
                  <img v-else :src="course.image" class="img-fluid">
                </a>
                <!-- <div class="mo-rating">
                  <i class="fas fa-star active"></i>
                  <i class="fas fa-star active"></i>
                  <i class="fas fa-star active"></i>
                  <i class="fas fa-star active"></i>
                  <i class="fas fa-star"></i>
                </div> -->
                <div class="price">
                  {{ course.cost }} ر.س
                </div>
              </div>
              <div class="info-here">
                <div class="course-head">
                  <a href="#!" class="auther fixall">
                    <img :src="course.teacher.image" class="img-fluid img">
                    <span class="name">
                      {{ course.teacher.fullName }}
                    </span>
                  </a>
                  <div class="tags">
                    <a href="#!" class="fixall tag type">
                      {{ course.category }}
                    </a>
                    <a href="#!" class="fixall tag level entry">
                      {{ course.level }}
                    </a>
                  </div>
                </div>
                <a href="#!" class="course-name fixall">
                  {{ course.name }}
                </a>
                <p class="course-desc fixall">
                  {{ course.about }}
                </p>
                <div class="course-foot">
                  <div class="foot-item">
                    {{ course.stats.lessonsCount }}
                    <i class="uil uil-file"></i>
                    <span class="lnr lnr-file-empty"></span>
                  </div>
                  <div class="foot-item">
                    {{ course.stats.totalHours }}
                    <i class="uil uil-clock"></i>
                    <span class="lnr lnr-clock"></span>
                  </div>
                  <div class="foot-item">
                    {{ course.stats.studentsCount }}
                    <i class="uil uil-user"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Pagination :currentPage="currentPage" :totalPages="totalPages" @page-change="handlePageChange" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End All-courses -->
</template>

<script>
  
import {mapState} from "vuex";
import store from '@/store';
import {API_URL} from '@//helpers/config.js';
import ApiRequest from "@/helpers/apiRequest";
import PageHeadComponent from "@/components/PageHeadComponent.vue";
import Pagination from "@/components/PaginationComponent.vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

export default {
  name: "CoursesIndex",
  components: {
    Carousel,
    Slide,
    PageHeadComponent,
    Pagination,
  },
  data() {
    return {
      pageTitle: "الدورات التي نقدمها",
      courses: [],
      topCourses: [],
      categories: [],
      activeTab: 0,
      sortOptionIndex: 0,
      filter: {
        category: 0,
        name: "",
      },
      sortArray: [
        {item:'', order:'', placeHolder:"الافتراضي"},
        {item:'name', order:'asc', placeHolder:"الإسم من أ - ي"},
        {item:'name', order:'desc', placeHolder:"الإسم من ي - أ"},
        {item:'cost', order:'asc', placeHolder:"حسب السعر (منخفض > مرتفع)"},
        {item:'cost', order:'desc', placeHolder:"حسب السعر (مرتفع > منخفض)"},
      ],
      currentPage:null,
      totalPages:null,
    };
  },
  methods: {
      getCourses(requiredPage = 1) {
          store.commit('setIsLoading', true);
          ApiRequest.get(API_URL + "/lms/courses?page=" + requiredPage + "&filter=" + this.jsonFilter + "&sort=" + this.jsonSort)
              .then(response => {
              this.courses = response.data.data;
              this.categories = response.data.categories;
              this.topCourses = [...this.courses].sort((a, b) => b.stats.studentsCount - a.stats.studentsCount ).slice(0,5);
              this.currentPage = response.data.meta.current_page;
              this.totalPages = response.data.meta.last_page;
              store.commit('setIsLoading', false);
              })
              .catch(error => {
              console.log(error);
              store.commit('setIsLoading', false);
              });
      },
      handlePageChange(page){
        this.getCourses(page);
      },
      sort(){
        this.getCourses();
      },
      filterByName(){
        this.getCourses();
      },
      filterByCat(id){
        this.setActive(id);
        this.filter.category = id;
        this.getCourses();
      },
      setActive(id){
        this.activeTab = id;
      },
      isActive(id){
        return this.activeTab == id ? 'active' : null;
      },
  },
  computed: {
    ...mapState(["isLoading"]),
    jsonFilter(){
      return JSON.stringify(this.filter);
    },
    jsonSort(){
      return JSON.stringify(this.sortArray[this.sortOptionIndex]);
    },
    slidesNo(){
      return this.topCourses.length > 3 ? 3 :  this.topCourses.length;
    },
  },
  mounted() {
    this.getCourses();
  },
};
</script>