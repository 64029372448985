<template>

  <section class="account-section">
    <div class="container">
      <div class="error-code">404</div>
      <div class="error-message">حدث خطأ</div>
      <p>
        ربما تمت إزالة الصفحة التي تبحث عنها، أو تم تغيير اسمها،
        أو غير متاح مؤقتا.
      </p>
      <p>
        <a class="btn btn-sm btn-info mt-4" @click="goBack">العودة للخلف</a>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFoundPage",
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-code {
  font-size: 100px;
  font-weight: bold;
  color: #008275;
  margin-bottom: 20px;
}

.error-message {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}
</style>