import {createStore} from 'vuex';
import axios from 'axios';
import {API_URL} from '@/helpers/config.js';

const store = createStore({
    state: {
        isLoading: false,
        token: localStorage.getItem('token'),
        user: JSON.parse(localStorage.getItem('user')),
        isLoggedIn: localStorage.getItem('token') !== null
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading;
        },
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        SET_USER(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        setIsLoggedIn(state, isLoggedIn) {
            state.isLoggedIn = isLoggedIn;
        }
    },
    actions: {
        initialize({commit}) {
            const token = localStorage.getItem('token');
            if (token) {
                commit('SET_TOKEN', token);
                const user = JSON.parse(localStorage.getItem('user'));
                if (user) {
                    commit('SET_USER', user);
                }
            }
        },

        login({commit}, credentials) {
            const headers = {
                'Accept-Language': localStorage.getItem("locale") || "en"
            };
            return axios.post(`${API_URL}/customer/login`, credentials, {
                headers
            })
                .then(response => {
                    commit('SET_TOKEN', response.data.data.token);
                    commit('SET_USER', response.data.data.user);
                    commit('setIsLoggedIn', true);
                });
        }
    },
});

export default store;