<template>

  <section class="register-section">
    <div class="container">
      <div class="login-form">
        <div class="about-title-sec">
          <h3>تسجيل جديد</h3>
          <svg width="46" height="14" viewBox="0 0 46 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.97127 0C2.14368 3.8726 4.42121 5.80792 8.80386 5.80792C11.4688 6.2032 18.7313 5.33436 30.5937 3.19944C33.2373 2.72589 35.5148 2.48911 37.4263 2.48911C39.3377 2.48911 42.1963 3.31881 46 4.97821C45.1826 7.17184 44.0652 9.60028 42.6476 12.2675C39.1887 11.0816 36.2705 10.4887 33.8972 10.4887C31.5238 10.4887 27.8415 11.0816 22.8564 12.2675C15.1426 13.966 9.37857 14.4161 5.56423 13.6157C1.7499 12.8173 -0.104052 10.7216 0.00450356 7.33426C0.11093 3.94696 0.766519 1.5009 1.97127 0Z"
                fill="#1E354C"></path>
          </svg>
        </div>

        <div class="errors">
          <ul>
            <li v-for="(error, index) in errors" :key="index" class="alert alert-danger">
              {{ error }}
            </li>
          </ul>
        </div>

        <form>
          <div class="form-group">
            <label for="fullname">الاسم</label>
            <input type="text" class="form-control" v-model="fullname" id="fullname" placeholder="الاسم بالكامل"
                   required>
          </div>
          <div class="form-group">
            <label for="username">اسم المستخدم</label>
            <input type="text" class="form-control" v-model="username" id="username" placeholder="الاسم بالكامل"
                   required>
          </div>
          <div class="form-group">
            <label for="email">البريد الإلكتروني</label>
            <input type="email" class="form-control" v-model="email" id="email" placeholder="الاسم بالكامل" required>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">كلمة المرور</label>
            <input type="password" v-model="password" class="form-control" id="exampleInputPassword1"
                   placeholder="*********"
                   required>
          </div>
          <div class="form-group">
            <label>الجنس</label>
            <div class="cont">
              <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                <option selected="">أختر الجنس</option>
                <option value="1">ذكر</option>
                <option value="2">أنثى</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputAge">السن</label>
            <input type="text" class="form-control" id="exampleInputAge" placeholder="السن">
          </div>
          <div class="form-group date-cont">
            <label for="exampleInputAge">تاريخ الميلاد</label>
            <input type="date" class="form-control" id="birthday" name="birthday">

          </div>
          <div class="form-group">
            <label for="exampleInputJob">الوظيفة</label>
            <input type="text" class="form-control" id="exampleInputjob" placeholder="الوظيفة">
          </div>
          <div class="form-group">
            <label for="city">المدينة</label>
            <div class="cont">
              <select id="city" class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                <option selected="">أختر المدينة</option>
                <option value="1">مكة</option>
                <option value="2">الرياض</option>
                <option value="3">المنطقة الشمالية</option>
                <option value="4">الخبر</option>
                <option value="5">أبها</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputAddress">مكان الاقامة</label>
            <input type="text" class="form-control" id="exampleInputAddress" placeholder="مكان الاقامة">
          </div>
        </form>

        <button type="submit" class="sec-prov" @click.prevent="register" :disabled="isButtonDisabled">
          تسجيل جديد
          <i v-if="isLoading" class="fa fa-spinner fa-spin"></i>
        </button>
      </div>
    </div>
  </section>

</template>

<script>

import {mapState} from "vuex";
import ApiRequest from "@/helpers/apiRequest";

export default {
  name: "RegisterPage",

  data() {
    return {
      email: '',
      password: '',
      username: '',
      fullname: '',
      errors: [],
      isButtonDisabled: false,
    }
  },

  methods: {
    register() {
      this.errors = []
      if (this.email === '' || this.password === '' || this.username === '' || this.fullname === '') {
        this.errors = [this.$t("login.missed_data")]
        return
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(this.email)) {
        this.errors = [this.$t("login.invalid_email")];
        return;
      }
      this.isButtonDisabled = true;
      this.$store.commit('setIsLoading', true)
      ApiRequest.post('/register', {
        username: this.username,
        email: this.email,
        password: this.password,
        fullname: this.fullname,
      }).then(response => {
        console.log(response)
        this.$store.commit('setIsLoading', false)
        window.location.href = '/login';
      }).catch((error) => {
        this.$store.commit('setIsLoading', false)
        this.isButtonDisabled = false;
        if (error.status_code !== 200) {
          this.errors = [error.response.message]
        }
      })
    },
  },

  computed: {
    ...mapState(["isLoading"]),
  },

}
</script>

<style scoped>

button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}


button[disabled]:hover {
  cursor: not-allowed;
  opacity: 0.5;
}


button[disabled] .theme-btn {
  background-color: gray;
  color: white;
}

.errors ul {
  list-style: none;
}
</style>