<template>

  <PageHeadComponent :page-title="pageTitle"/>


  <section class="register-section">
    <div class="container">
      <div class="search-marriage-blog edit">

        <div class="select-flex">
          <div class="search-select-blog">
            <div class="form-group">
              <label for="exampleInputName">الاسم</label>
              <input v-model="profileData.fullname" type="text" class="form-control" id="exampleInputName"
                     placeholder="الأسم بالكامل">
            </div>
          </div>
          <div class="search-select-blog">
            <div class="form-group">
              <label for="email">البريد الإلكتروني</label>
              <input v-model="profileData.email" type="email" class="form-control" id="email"
                     placeholder="examlpe@gamil.com"
              >
            </div>

          </div>
          <div class="search-select-blog">
            <div class="form-group">
              <label for="job">الوظيفة</label>
              <input type="text" v-model="profileData.job" class="form-control" id="job" placeholder="الوظيفة">
            </div>
          </div>

          <div class="search-select-blog">
            <div class="form-group te">
              <label>التعليم</label>
              <div class="cont">
                <select v-model="profileData.education" class="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example">
                  <option disabled selected>التعليم</option>
                  <option v-for="(index, edu) in selectableData.education" :key="edu" :value="edu">
                    {{ index }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="search-select-blog">
            <div class="form-group te">
              <label>لون البشرة</label>
              <div class="cont">
                <select v-model="profileData.skin_color" class="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example">
                  <option selected>لون البشرة</option>
                  <option v-for="(index, skin) in selectableData.skin_colors" :key="skin" :value="skin">
                    {{ index }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="search-select-blog">
            <div class="form-group te">
              <label>الجنسية</label>
              <div class="cont">
                <select v-model="profileData.nationality" class="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example">
                  <option selected>أختر الجنسية</option>
                  <option v-for="(index, nation) in selectableData.nationalities" :key="nation" :value="nation">
                    {{ index }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="search-select-blog">
            <div class="form-group te">
              <label for="exampleInputCity">المدينة</label>
              <div class="cont">
                <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                  <option selected>أختر المدينة</option>
                  <option value="1">مكة</option>
                  <option value="2">الرياض</option>
                  <option value="3">المنطقة الشمالية</option>
                  <option value="4">الخبر</option>
                  <option value="5">أبها</option>
                </select>
              </div>
            </div>
          </div>

          <div class="search-select-blog">
            <div class="form-group te">
              <label for="exampleInputCity">الحالة المادية</label>
              <div class="cont">
                <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">

                  <option selected>الحالة المادية</option>
                  <option value="1">الحالة المادية</option>
                  <option value="2">الحالة المادية</option>
                  <option value="3">الحالة المادية</option>
                  <option value="4">الحالة المادية</option>
                  <option value="5">الحالة المادية</option>
                  <option value="6">الحالة المادية</option>

                </select>
              </div>
            </div>
          </div>

          <div class="search-select-blog">
            <div class="form-group te">
              <label for="exampleInputCity">الأصل</label>
              <div class="cont">
                <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                  <option selected>الأصل</option>
                  <option value="1">الأصل</option>
                  <option value="2">الأصل</option>
                  <option value="3">الأصل</option>
                  <option value="4">الأصل</option>
                  <option value="5">الأصل</option>
                </select>
              </div>
            </div>
          </div>

          <div class="search-select-blog">
            <div class="form-group">
              <label for="exampleInputAddress">مكان الاقامة</label>
              <input type="text" class="form-control" id="exampleInputAddress" placeholder="مكان الاقامة"
              >
            </div>
          </div>

        </div>

        <button type="button" @click="update"
                :disabled="isButtonClicked"
                class="sec-prov">
          حفظ
          <i v-if="isButtonClicked" class="fa fa-spinner fa-spin mx-2"></i>
        </button>
        <AlertsComponent :alerts="alerts"/>
      </div>
    </div>
  </section>


</template>


<script>

import ApiRequest from "@/helpers/apiRequest";
import PageHeadComponent from "@/components/PageHeadComponent.vue";
import AlertsComponent from "@/components/AlertsComponent.vue";

export default {
  name: "ProfilePage",
  components: {
    PageHeadComponent,
    AlertsComponent
  },
  data() {
    return {
      pageTitle: "تعديل بياناتى",
      selectableData: [],
      profileData: {},
      loading: false,
      isButtonClicked: false,
      alerts: []
    };
  },
  mounted() {
    this.fetchProfileData();
  },
  methods: {
    fetchProfileData() {
      ApiRequest.get('/benaa/edit-profile').then(response => {
        console.log(response);
        this.selectableData = response.data.selectableData;
        this.profileData = response.data.user;
      }).catch((error) => {
        console.log(error)
      })
    },
    update() {
      this.loading = true;
      this.isButtonClicked = true;
      ApiRequest.post('/benaa/update-profile', this.profileData).then(response => {
        console.log(response)
        this.alerts = ["تم حفظ بيانات الملف الشخصي بنجاح"];
        this.loading = false;
        this.isButtonClicked = false;
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>