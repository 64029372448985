<template>

  <footer>
    <div class="container">
      <div class="footer">
        <div class="row">
          <div class="col-md-12 col-lg-4 col-xl-5">
            <div class="footer-about">
              <div class="footer-logo">
                <img src="/assets/images/logo-foot.png" class="img-fluid">
              </div>
              <p class="footer-text fixall">
                في مجال التعليم عن بعد والاستشارات عن طريق أفضل الخبراء والمدرسين، كما تهدف إلى الارتقاء بالمستوى
                الثقافي للمواطن العربي هي منصة تهدف إلى تقديم أفضل الخدمات.
              </p>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <div class="nav-foot-cont">
              <h4 class="foot-head nav-foot-head fixall">
                روابط سريعة
              </h4>
              <ul class="foot-nav list-unstyled foot-acc fixall">

                <li>
                  <a href="#!" class="fixall">
                    خدماتنا
                  </a>
                </li>
                <li>
                  <a href="#!" class="fixall">
                    الشروط و الأحكام
                  </a>
                </li>
                <li>
                  <a href="#!" class="fixall">
                    سياسة الخصوصية
                  </a>
                </li>
                <li>
                  <a href="#!" class="fixall">
                    تواصل معنا
                  </a>
                </li>

              </ul>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <div class="nav-foot-cont">
              <h4 class="foot-head nav-foot-head fixall">
                تواصل معنا
              </h4>
              <ul class="contact-nav list-unstyled foot-acc fixall">
                <li>
                  <i class="fas fa-map-marker-alt"></i>
                  <a href="#!" class="fixall">
                    مكة المكرمة - جدة - الرياض
                  </a>
                </li>
                <li>
                  <i class="fas fa-phone"></i>
                  <a href="tel:9612535353111" class="fixall">
                    +9612535353111
                  </a>
                </li>
                <li>
                  <i class="fas fa-envelope-open"></i>
                  <a href="mail:info@benna.sa" class="fixall">
                    info@benna.sa
                  </a>
                </li>


              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="last-footer">
        <div class="social-list">
          <div class="social-title">
            <span>تابعنا على </span>
          </div>
          <ul class="soi-l">
            <li class="social-flex">

              <a href="#!" class="social fixall">
                <i class="fab fa-snapchat-ghost"></i>
              </a>
              <a href="#!" class="social fixall">
                <i class="fab fa-instagram"></i>
              </a>
              <a href="#!" class="social fixall">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#!" class="social fixall">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
          </ul>
        </div>
        <p class="client fixall">
          جميع الحقوق محفوظة 2024©
        </p>

      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'FooterComponent',
}

</script>
