<template>
  <main id="main">
    <div class="container">
      <div class="main">
        <h1 class="main-title fixall wow fadeInUp" style="visibility: visible;">
          {{$t('head.main.title')}}
        </h1>
        <h4 class="main-subtitle fixall wow fadeInUp" data-wow-deley="0.2s" style="visibility: visible;">
          والمسارات التعليمية في مختلف المجالات للأساتذة متخصصين
        </h4>
        <form class="search-form wow fadeInUp" data-wow-deley="0.4s" style="visibility: visible;">
          <div class="search-cont">
            <input type="text" class="search-input fixall" placeholder="ابحث هنا ماذا تريد">
            <button class="search-btn fixall">
              بحث
            </button>
          </div>
        </form>
        <div class="main-img wow zoomIn animated animated" style="visibility: visible;">
          <img src="/assets/images/main.png" alt="" class="img-fluid">
        </div>
      </div>
    </div>
  </main>
</template>


<script>
export default {
  name: "MainSec",
}
</script>
