<template>

  <MainSec/>
  <AboutSec/>
  <TabsSec/>
  <ConsultingSec/>
  <MarriageSec/>
  <NewsSec/>

</template>

<script>

import MainSec from './MainSec.vue'
import AboutSec from './AboutSec.vue'
import TabsSec from './TabsSec.vue'
import ConsultingSec from './ConsultingSec.vue'
import MarriageSec from './MarriageSec.vue'
import NewsSec from './NewsSec.vue'

export default {
  name: "IndexPage",
  components: {
    MainSec,
    AboutSec,
    TabsSec,
    ConsultingSec,
    MarriageSec,
    NewsSec,
  }
}
</script>
