<template>

  <!-- End Header -->
  <section class="marriage-single-section">
    <div class="container">

      <div v-if="loading" class="text-center">
        <img :src="'/assets/loading.gif'" alt="loader" width="30">
        <br>
        جار التحميل ..
      </div>

      <div class="marr-big-content" v-if="!loading">
        <div class="marr-title-flex">
          <div class="marr-name">
            <h3>{{ userData.fullname }}</h3>
            <div class="marr-one">
              <div class="mar-age">
                <span>{{ userData.age }}</span>
                سنه
              </div>
              <div class="mar-age">
                <i class="uil uil-location-point"></i>
                {{ userData.nationality }}
              </div>
              <div class="mar-age">
                <i class="uil uil-users-alt"></i> {{ userData.marital_status }}
              </div>
              <div class="mar-age">
                <i class="uil uil-chat-bubble-user"></i>
                {{ userData.job }}
              </div>
            </div>
          </div>
          <div class="marr-links">
            <div class="mar-link">
              <a href="#!">
                <i class="uil uil-comment-dots"></i>
                رسالة</a>
            </div>
            <div class="mar-link">
              <a href="javascript:void(0)" @click="handleClick">
                <i class="uil uil-heart" :class="{ 'selected': isHeartSelected }"></i>
                إهتمام
              </a>
            </div>
            <div class="mar-link">
              <a href="#!">
                <i class="uil uil-ban"></i>
                تجاهل</a>
            </div>
            <div class="mar-link red">
              <a href="#!">
                <i class="uil uil-times-circle"></i>
                إبلاغ</a>
            </div>
          </div>
        </div>
        <div class="marr-details">
          <span>التفاصيل</span>
          <div class="marr-details-flex">
            <div class="marr-detail">
              <h3>السن</h3>
              <span> {{ userData.age }} سنه</span>
            </div>
            <div class="marr-detail">
              <h3>الجنسية</h3>
              <span>{{ userData.nationality }}</span>
            </div>
            <div class="marr-detail">
              <h3>مكان الإقامة</h3>
              <span>{{ userData.nationality }}</span>
            </div>
            <div class="marr-detail">
              <h3>الحالة العائلية</h3>
              <span>{{ userData.marital_status }}</span>
            </div>
            <div class="marr-detail">
              <h3>نوع الزواج</h3>
              <span>{{ userData.marriage_type }}</span>
            </div>
            <div class="marr-detail">
              <h3>الحالة الصحة</h3>
              <span>{{ userData.health_status }}</span>
            </div>
            <div class="marr-detail">
              <h3>المستوى العلمي</h3>
              <span>{{ userData.education }}</span>
            </div>
            <div class="marr-detail">
              <h3>العمل</h3>
              <span>{{ userData.job }}</span>
            </div>
            <div class="marr-detail">
              <h3>لون البشرة</h3>
              <span>{{ userData.skin_color }}</span>
            </div>
            <div class="marr-detail">
              <h3>الوزن والطول</h3>
              <span>{{ userData.weight }} كج - {{ userData.height }} سم</span>
            </div>
            <div class="marr-detail">
              <h3>آخرى</h3>
              <span>{{ userData.others }}</span>
            </div>
          </div>
        </div>
        <div class="marr-details">
          <span>الشروط والموصفات التي أرغب أن تكون في الطرف الآخر</span>
          <div class="marr-details-flex">
            <div class="marr-detail">
              <h3>الجنسية</h3>
              <span>سعودى</span>
            </div>

            <div class="marr-detail">
              <h3>القبيلة</h3>
              <span>بدون شروط</span>
            </div>
            <div class="marr-detail">
              <h3>الحالة الإجتماعية</h3>
              <span>مطلقة</span>
            </div>
            <div class="marr-detail">
              <h3>مكان الإقامة</h3>
              <span>الرياض - سعودي</span>
            </div>
            <div class="marr-detail">
              <h3>السن</h3>
              <span>34 -42 سنة</span>
            </div>


            <div class="marr-detail">
              <h3>التعليم</h3>
              <span>جامعي -دكتوراه</span>
            </div>

            <div class="marr-detail">
              <h3>العمل</h3>
              <span>ربة منزل - معلمة </span>
            </div>
            <div class="marr-detail">
              <h3>الحالة المادية</h3>
              <span>متوسطة</span>
            </div>
            <div class="marr-detail">
              <h3>التدين</h3>
              <span>متوسط</span>
            </div>
            <div class="marr-detail">
              <h3>لون البشرة</h3>
              <span>ابيض</span>
            </div>
            <div class="marr-detail">
              <h3>الوزن</h3>
              <span>55 - 70 كجم</span>
            </div>
            <div class="marr-detail">
              <h3>الطول</h3>
              <span>158 - 170 سم</span>
            </div>
            <div class="marr-detail">
              <h3>آخرى</h3>
              <span>مطلقة بدون أبناء وموظفة .. باقي التفاصيل عند التواصل</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</template>


<script>
import ApiRequest from "@/helpers/apiRequest";

export default {
  name: "MatchesShow",
  props: ['username'],
  data() {
    return {
      pageTitle: "Single",
      userData: {},
      loading: false,
      isHeartSelected: false
    };
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    fetchProfile() {
      this.loading = true;
      ApiRequest.get('/benaa/get-profile/' + this.username).then(response => {
        this.userData = response.data.data;
        this.isHeartSelected = this.userData.isFavorite ?? false;
        this.loading = false;
      }).catch((error) => {
        console.log(error)
      })
    },
    handleClick() {
      this.loading = true;
      ApiRequest.post('/benaa/favorites/' + this.userData.profile_id).then(() => {
        this.loading = false;
        this.isHeartSelected = !this.isHeartSelected;
      }).catch((error) => {
        console.log(error)
      })

    }
  }
}
</script>

<style scoped>
.selected, .selected:hover {
  color: #fff;
  background: #cc0505;
}
</style>