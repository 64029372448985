<template>
  <div id="app">

    <HeaderComponent/>
    <router-view></router-view>
    <FooterComponent/>

  </div>
</template>

<script>

import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent
  },
  computed: {
    isRTL() {
      return this.$i18n.locale === "ar";
    },
  },
}
</script>

